// 表单接口
import base from './base'
import axios from './http'
const type = {
  postType (typeInfo) {
    return axios.post(`${base.sq}/categories`, typeInfo)
  },
  delType (id) {
    return axios.delete(`${base.sq}/categories/${id}`)
  },
  putType (id, typeInfo) {
    return axios.put(`${base.sq}/categories/${id}`, typeInfo)
  },
  getType (model) {
    return axios.get(`${base.sq}/categories/${model}`)
  },
}
export default type
