<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="表单分类管理"
      class="px-5 py-3 mb-5"
    >
      <div class="my-5">
        <v-btn
          color="success"
          @click="editFormTypeBtn"
        >
          <v-icon left>
            fa fa-plus
          </v-icon>
          创建分类
        </v-btn>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>#</th>
            <th>分类名称</th>
            <th>分类ID</th>
            <th>父级栏目</th>
            <th class="text-right">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(type, index) in types"
            :key="type.code"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ type.name }}</td>
            <td>{{ type.code }}</td>
            <td>{{ type.parentId }}</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="editTypeBtn(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
                @click="delType(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div>
    </base-material-card>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>分类信息</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="name"
                    label="分类名称"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="parentId"
                    :items="['顶级分类']"
                    label="上级分类"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="weight"
                    label="排序"
                    hint="排序数值越大越靠后"
                    persistent-hint
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="分类介绍"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="dialog = false"
          >
            关闭
          </v-btn>
          <v-btn
            v-if="saveBtnShow"
            color="success"
            @click="createFormType"
          >
            保存
          </v-btn>
          <v-btn
            v-if="!saveBtnShow"
            color="success"
            @click="editType"
          >
            编辑
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import type from '../../api/type'
  export default {
    data () {
      return {
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
        name: null,
        parentId: null,
        weight: null,
        types: [],
        saveBtnShow: true,
        typeId: '',
      }
    },
    created () {
      this.getType()
    },
    methods: {
      editFormTypeBtn () {
        this.dialog = true
        this.saveBtnShow = true
      },
      createFormType () {
        type.postType({
          model: 'form',
          name: this.name,
          parentId: null,
          weight: 0,
        })
          .then(res => {
            this.dialog = false
            this.$swal({
              title: '创建成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getType()
          })
      },
      getType () {
        type.getType('form')
          .then(res => {
            this.types = res.data.data
          })
      },
      editTypeBtn (index) {
        this.dialog = true
        this.saveBtnShow = false
        this.name = this.types[index].name
        this.parentId = this.types[index].parentId
        this.weight = this.types[index].weight
        this.typeId = this.types[index].id
      },
      editType () {
        type.putType(this.typeId, {
          model: 'form',
          name: this.name,
          parentId: null,
          weight: 0,
        })
          .then(res => {
            this.dialog = false
            this.$swal({
              title: '修改成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getType()
          })
      },
      delType (index) {
        this.$swal({
          title: '你确定要删除"' + this.types[index].name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            type.delType(this.types[index].id)
              .then(res => {
                this.types.splice(index, 1)
                this.$swal({
                  title: '删除成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err.response.data.msg,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
  .form_box{
    max-height: 60vh;
    overflow: auto;
  }
</style>
